import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

import Container from "../../components/Container/Container"
import Product from "../../components/Product/Product"
import ProductHeader from "../../components/Product/ProductHeader"
import ProductDescription from "../../components/Product/ProductDescription"
import ProductDocs from "../../components/Product/ProductDocs"

import ProductDocsList from "../../components/Product/ProductDocsList"
import ProductAccordionListItem from "../../components/Product/ProductAccordion/ProductAccordionListItem"

const Minicity = () => {
  const { minicity } = useStaticQuery(
    graphql`
      query {
        minicity: file(relativePath: { eq: "products/minicity.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <Layout pageInfo={{ pageName: "minicity" }}>
      <SEO title="Mini City Cetetherm" />
      <Container>
        <Product>
          <ProductHeader headerImage={minicity.childImageSharp.fluid}>
            Mini City Cetetherm to kompletny dwufunkcyjny węzeł cieplny
            dostosowany do współpracy z wysokoparametrową siecią cieplną.
            Zapewnia zasilanie w ciepło instalacji centralnego ogrzewania (c.o.)
            i przygotowania ciepłej wody (c.w.u.). Kompaktowe węzły Mini City
            stosuje się w mieszkaniach oraz domach jednorodzinnych podłączonych
            do lokalnego systemu ogrzewania lub sieci ciepłowniczej.
          </ProductHeader>
          <ProductDocs>
            <ProductDocsList title="Karty katalogowe">
              <ProductAccordionListItem linkTo="/minicity/minicity.pdf">
                Cetetherm Mini City - broszura
              </ProductAccordionListItem>
            </ProductDocsList>
          </ProductDocs>
          <ProductDescription>
            <p>
              Mini City wyposażony jest w automatyczną, indywidualną regulację
              temperatury instalacji - dla c.o. oraz c.w.u. oraz dwa płytowe
              lutowane wymienniki ciepła.
            </p>
            <p>
              Temperatura c.w.u. jest regulowana w zależności od zapotrzebowania
              i parametrów wody sieciowej. Temperatura c.o. regulowana
              automatycznie w zależności od temperatury zewnątrznej i/lub
              wymaganej temperatury wewnątrz mieszkania.
            </p>
          </ProductDescription>
        </Product>
      </Container>
    </Layout>
  )
}

export default Minicity
